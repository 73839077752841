<template>
  <MouseFollow />
  <div class="loading" v-if="loading">
    <div
      class="words bold title-size"
      data-aos="zoom-out"
      data-aos-duration="2000"
    >
      Loading...
    </div>
    <Loader />
  </div>
  <video id="bgVideo" controls preload="true" autoplay loop muted>
    <source :src="require('@/assets/background.mp4')" type="video/mp4" />
  </video>
  <div class="navigator">
    <WebLogo />
  </div>
  <router-view />
</template>
<script>
// @ is an alias to /src
import MouseFollow from "@/components/MouseFollow.vue";
import WebLogo from "@/components/objects/WebLogo.vue";
import Loader from "@/components/LoaderCom.vue";
import "@/assets/main.css";
export default {
  data() {
    return {
      loading: true,
    };
  },
  name: "App",
  components: {
    WebLogo,
    Loader,
    MouseFollow,
  },
  mounted() {
    setTimeout(() => {
      this.loading = false;
    }, 3000);
  },
};
</script>
<style>
video#bgVideo {
  position: fixed;
  right: 0;
  bottom: 0;
  width: auto;
  min-width: 100%;
  height: auto;
  min-height: 100%;
  z-index: -100;
  background-size: cover;
  background: #2c3e50;
}
@media only screen and (min-width: 1024px) {
  .navigator {
    z-index: 100;
    position: absolute;
    left: 100%;
    text-align: right;
    transform: translateX(-110%);
  }
}
@media only screen and (max-width: 1023px) {
  .navigator {
    display: flex;
    text-align: right;
    z-index: 100;
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
    flex-direction: row-reverse;
  }
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  min-height: 100vh;
}

nav {
  padding: 30px;
}
body {
  overflow-y: scroll;
  overflow-x: hidden;
}

nav a {
  text-decoration: none;
  font-weight: bold;
  color: #fff;
}
nav a:hover {
  font-weight: bold;
  color: #fff;
  text-decoration: underline;
}

nav a.router-link-exact-active {
  color: #faa845;
  text-decoration: underline;
}
body {
}
</style>
