<template>
    <nav class="col-5 col-md-6">
    <div class="col nav mx-auto pt-4" data-aos="fade-up" data-aos-duration="3000">
        <div class="col-12" @click="rotate('front')"><router-link to="/">HOME</router-link> </div>
        <div class="col-12" @click="rotate('left')"><a><router-link to="/projects">PROJECTS</router-link> </a></div>
        <div class="col-12" @click="rotate('right')"><router-link to="/education">EDUCATION</router-link> </div>
        <div class="col-12" @click="rotate('bottom')"><a><router-link to="/experience">EXPERIENCE</router-link></a></div>
        <div class="col-12" @click="rotate('bottom')"><a href="https://jowettc.github.io/MyWebsite/cv/CV_of_JowettChng.pdf">RESUME</a></div>
    </div>
    </nav>
    
    <div class="scene pt-4" data-aos="fade-down" data-aos-delay="1000" data-aos-duration="2000">
        <div class="cube">
            <div class="cube__face cube__face--front">Home</div>
            <div class="cube__face cube__face--back">Home</div>
            <div class="cube__face cube__face--right">Education</div>
            <div class="cube__face cube__face--left">Projects</div>
            <div class="cube__face cube__face--top">Socials</div>
            <div class="cube__face cube__face--bottom">Experience</div>
        </div>
    </div>

    


</template>
<script>
export default {
  name: 'WebLogo',
  data() {
    return {
      isStart: false
    }
  },
  components:{
    // PopUp,
  },
  methods:{
    rotate(direction){
        if(!this.isStart){
            document.getElementsByClassName("scene")[0].classList.remove("absolute")
            document.getElementsByClassName("scene")[1].classList.remove("absolute")
            this.isStart = true;

        }
        // var cube = document.getElementsByClassName("cube")[0].classList;
        var cube1 = document.getElementsByClassName("cube")[1].classList;
        // cube.remove("rotate")
        cube1.remove("rotate")
        if (direction == "front"){
            // cube.remove("show-right")
            // cube.remove("show-left")
            // cube.remove("show-bottom")
            // cube.add("show-front")

            cube1.remove("show-right")
            cube1.remove("show-left")
            cube1.remove("show-bottom")
            cube1.add("show-front")

        }
        else if(direction =="right"){
            // cube.remove("show-front")
            // cube.remove("show-left")
            // cube.remove("show-bottom")
            // cube.add("show-right")
            cube1.remove("show-front")
            cube1.remove("show-left")
            cube1.remove("show-bottom")
            cube1.add("show-right")
        }
        else if(direction =="left"){
            // cube.remove("show-right")
            // cube.remove("show-front")
            // cube.remove("show-bottom")
            // cube.add("show-left")
            cube1.remove("show-right")
            cube1.remove("show-front")
            cube1.remove("show-bottom")
            cube1.add("show-left")
        }
        else if(direction =="bottom"){
            // cube.remove("show-right")
            // cube.remove("show-front")
            // cube.remove("show-left")
            // cube.add("show-bottom")
            cube1.remove("show-right")
            cube1.remove("show-front")
            cube1.remove("show-left")
            cube1.add("show-bottom")
        }
            

        // console.log(direction)
        // var cube = document.getElementsByClassName("cube")[0].classList
        // cube.toggle("show-"+direction);
    }
  },
  mounted(){
    console.log("Web application loaded")
  }
}
</script>
<style>
.nav{
    max-width: 400px;
}

body { font-family: sans-serif; }

.scene {
  width: 100px;
  height: 100px;
  /* border: 1px solid #CCC; */
  margin: auto;
  perspective: 400px;
  transition: 1s;
}
.absolute{
    position:absolute;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%) translateZ(500px)!important;
    
}
.cube {
  width: 100px;
  height: 100px;
  position: relative;
  transform-style: preserve-3d;
  transform: translateZ(0px) rotateY(45deg) rotateX(-45deg);
  transition: transform 1s;
}

.cube.show-front  { transform: translateZ(-350px) rotateY(   0deg); }
.cube.show-right  { transform: translateZ(-350px) rotateY( -90deg); }
.cube.show-back   { transform: translateZ(-350px) rotateY(-180deg); }
.cube.show-left   { transform: translateZ(-350px) rotateY(  90deg); }
.cube.show-top    { transform: translateZ(-350px) rotateX( -90deg); }
.cube.show-bottom { transform: translateZ(-350px) rotateX(  90deg); }

.cube__face {
  position: absolute;
  width: 150px;
  height: 150px;
  /* border: 1px solid grey; */
  line-height: 150px;
  font-size: 20px;
  font-weight: bold;
  color: white;
  text-align: center;
  border-radius:15px;
}
.rotate {
  animation: rotation 10s infinite linear;
}
@keyframes rotation {
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(359deg);
  }
}
.cube__face--front  { 
/* From https://css.glass */
background: rgba(255, 255, 255, 0.25);
border-radius: 16px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(6.2px);
-webkit-backdrop-filter: blur(6.2px);
}
.cube__face--right  { /* From https://css.glass */
background: rgba(255, 255, 255, 0.25);
border-radius: 16px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(6.2px);
-webkit-backdrop-filter: blur(6.2px);}
.cube__face--back   { /* From https://css.glass */
background: rgba(255, 255, 255, 0.25);
border-radius: 16px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(6.2px);
-webkit-backdrop-filter: blur(6.2px);}
.cube__face--left   { /* From https://css.glass */
background: rgba(255, 255, 255, 0.25);
border-radius: 16px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(6.2px);
-webkit-backdrop-filter: blur(6.2px);}
.cube__face--top    { /* From https://css.glass */
background: rgba(255, 255, 255, 0.25);
border-radius: 16px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(6.2px);
-webkit-backdrop-filter: blur(6.2px);}
.cube__face--bottom { /* From https://css.glass */
background: rgba(255, 255, 255, 0.25);
border-radius: 16px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(6.2px);
-webkit-backdrop-filter: blur(6.2px);}

.cube__face--front  { transform: rotateY(  0deg) translateZ(100px); }
.cube__face--right  { transform: rotateY( 90deg) translateZ(100px); }
.cube__face--back   { transform: rotateY(180deg) translateZ(100px); }
.cube__face--left   { transform: rotateY(-90deg) translateZ(100px); }
.cube__face--top    { transform: rotateX( 90deg) translateZ(100px); }
.cube__face--bottom { transform: rotateX(-90deg) translateZ(100px); }

label { margin-right: 10px; }

</style>