<template>
    <div class="mouse-image" id="mouse-follow">
        <div class="scene pt-4" data-aos="fade-down" data-aos-delay="1000" data-aos-duration="2000" width="100">
        <div class="cube">
            <div class="cube__face cube__face--front">DevOps</div>
            <div class="cube__face cube__face--back">FullStack</div>
            <div class="cube__face cube__face--right">Scrum</div>
            <div class="cube__face cube__face--left">Cloud</div>
            <div class="cube__face cube__face--top">Sql</div>
            <div class="cube__face cube__face--bottom">Cloud</div>
        </div>
    </div>
    </div>
</template>
<script>
export default {
    name:'MouseFollow',
    created(){

        window.setInterval(function(){
        // call your function here
            console.log("rotate")
            var myArray = ['show-right', 'show-left','show-front','show-back'];    
            const randomElement = myArray[Math.floor(Math.random() * myArray.length)];
            var cube = document.getElementsByClassName("cube")[0].classList;
            myArray.forEach(function(x){
                cube.remove(x)
            });
            cube.add(randomElement)
        }, 3000);
            
    },
    mounted(){
        let circle = document.getElementById('mouse-follow');
        const onMouseMove = (e) =>{
        circle.style.left = e.pageX+10 + 'px';
        circle.style.top = e.pageY+10 + 'px';
        }
        document.addEventListener('mousemove', onMouseMove);
    },
    methods:{
        // randomFaceCube(test){
        //     console.log(test)
        //     var myArray = ['show-right', 'show-left', 'show-bottom','show-front'];    
        //     const randomElement = myArray[Math.floor(Math.random() * myArray.length)];
        //     var cube = document.getElementsByClassName("cube")[0].classList;
        //     myArray.forEach(function(x){
        //         cube.remove(x)
        //     });
        //     cube.add(randomElement)
        // }
    }
}
</script>
<style scoped>
.mouse-image{
    position:absolute;
    z-index:999;
    display:flex;
}
.middle{
    display:flex;
    flex-direction:column;
}

.scene {
  width: 50px;
  height: 50px;
  /* border: 1px solid #CCC; */
  margin: auto;
  perspective: 800px;
  transition: 1s;
}
.absolute{
    position:absolute;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%) translateZ(100px)!important;
    
}
.cube {
  width: 100px;
  height: 100px;
  position: relative;
  transform-style: preserve-3d;
  transform: translateZ(0px) rotateY(45deg) rotateX(-45deg);
  transition: transform 1s;
}

.cube.show-front  { transform: translateZ(-350px) rotateY(   0deg); }
.cube.show-right  { transform: translateZ(-350px) rotateY( -90deg); }
.cube.show-back   { transform: translateZ(-350px) rotateY(-180deg); }
.cube.show-left   { transform: translateZ(-350px) rotateY(  90deg); }
.cube.show-top    { transform: translateZ(-350px) rotateX( -90deg); }
.cube.show-bottom { transform: translateZ(-350px) rotateX(  90deg); }

.cube__face {
  position: absolute;
  width: 50px;
  height: 50px;
  /* border: 1px solid grey; */
  line-height: 50px;
  font-size: 10px;
  font-weight: bold;
  color: white;
  text-align: center;
  border-radius:15px;
}
.rotate {
  animation: rotation 10s infinite linear;
}
@keyframes rotation {
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(359deg);
  }
}
.cube__face--front  { 
/* From https://css.glass */
background: rgba(255, 255, 255, 0.25);
border-radius: 16px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(6.2px);
-webkit-backdrop-filter: blur(6.2px);
}
.cube__face--right  { /* From https://css.glass */
background: rgba(255, 255, 255, 0.25);
border-radius: 16px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(6.2px);
-webkit-backdrop-filter: blur(6.2px);}
.cube__face--back   { /* From https://css.glass */
background: rgba(255, 255, 255, 0.25);
border-radius: 16px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(6.2px);
-webkit-backdrop-filter: blur(6.2px);}
.cube__face--left   { /* From https://css.glass */
background: rgba(255, 255, 255, 0.25);
border-radius: 16px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(6.2px);
-webkit-backdrop-filter: blur(6.2px);}
.cube__face--top    { /* From https://css.glass */
background: rgba(255, 255, 255, 0.25);
border-radius: 16px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(6.2px);
-webkit-backdrop-filter: blur(6.2px);}
.cube__face--bottom { /* From https://css.glass */
background: rgba(255, 255, 255, 0.25);
border-radius: 16px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(6.2px);
-webkit-backdrop-filter: blur(6.2px);}

.cube__face--front  { transform: rotateY(  0deg) translateZ(33px); }
.cube__face--right  { transform: rotateY( 90deg) translateZ(33px); }
.cube__face--back   { transform: rotateY(180deg) translateZ(33px); }
.cube__face--left   { transform: rotateY(-90deg) translateZ(33px); }
.cube__face--top    { transform: rotateX( 90deg) translateZ(33px); }
.cube__face--bottom { transform: rotateX(-90deg) translateZ(33px); }

label { margin-right: 10px; }
</style>