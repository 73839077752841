<template>
  
  <div
    class="hero row mx-auto p-4 w-100 h-100 mb-4"
    data-aos="zoom-out-right"
    data-aos-duration="2000"
  >
    <div
      class="col-12 col-md-6 content p-4 d-flex flex-column justify-content-center text-center text-sm-left"
    >
      Turning Ideas into<span
        class="text-animation"
        data-aos="zoom-out-up"
        data-aos-duration="2000"
        data-aos-delay="1000"
        >Solutions</span
      >is my calling
      <br />
      <br />
      <div class="wrap justify-self-end">
        <router-link to="/projects"
          ><button class="button">Projects</button></router-link
        >
      </div>
    </div>
    <div
      class="col-12 col-md-6 d-flex align-items-center justify-content-center flex-column"
    >
      <img :src="require('@/assets/DP.png')" data-aos="zoom-in-left"
        data-aos-duration="2000"
        data-aos-delay="1500"/>
      <br />
      <div
        class="lead text-center display-6 font-weight-bold text-white job-desc" 
        data-aos="zoom-in-left"
        data-aos-duration="2000"
        data-aos-delay="1000"
      >
        Full-Stack Developer
      </div>
      <br />
      <br />
      <div class="social-media" data-aos="zoom-in-left"
        data-aos-duration="2000"
        data-aos-delay="2000">
        <a href="https://www.instagram.com/jowett_chng"><img class="mx-3" :src="require('@/assets/socialmedia/insta.png')" /></a>
        <a href="https://github.com/JowettC"><img class="mx-3" :src="require('@/assets/socialmedia/github.png')" /></a>
        <a href="https://sg.linkedin.com/in/jowett-chng"><img class="mx-3" :src="require('@/assets/socialmedia/linkedin.png')" /></a>
      </div>
    </div>
  </div>
</template>
<script>

export default {
    name: "HeroCom",
    data() {
        return {
            loading:true
        }
    },
    components:{
     
    },
    mounted(){
        setTimeout(() => {
            this.loading = false
        }, 3000);
    },
    methods:{

    }

}
</script>
<style scoped>
.hero {
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.2);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  max-width: 80%;
  min-height: 500px;
}

.text-animation {
  font-family: arial black;
  /* font-size: 70px; */
  background-image: linear-gradient(
    to right,
    rgb(195 134 61),
    orange,
    rgb(250 169 69),
    rgb(195 134 61),
    rgb(195 134 61)
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: rainbow-animation 100s linear infinite;
}

@keyframes rainbow-animation {
  to {
    background-position: 4500vh;
  }
}


.content {
  font-size: 4rem;
  text-align: left;
  line-height: 100%;
  letter-spacing: -0.03em;
  color: white;
  font-weight: bold;
}

@media only screen and (max-width: 600px) {
  .content {
    font-size: 3rem;
  }
}
</style>
