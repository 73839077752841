<template>
  <div class="home d-flex align-items-center justify-content-center lower">
    <HeroCom/>
    
  </div>
</template>

<script>
// @ is an alias to /src
import HeroCom from "@/components/home/HeroCom.vue"

export default {
  name: 'HomeView',
  components: {
    HeroCom
  }
}
</script>
<style>
.home{
  min-height:100vh;
  min-width:100vw;
  
}
</style>
